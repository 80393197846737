import React from "react";
import { Pagination, Scrollbar, A11y,Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const Gallery = () => {
  const style = {
    wrapper: "my-12 w-[90%] md:w-[30%] mx-auto",
    para: " text-2xl md:text-2xl text-center mx-auto",
  };
  return (
    <div className={style.wrapper}>
      <Swiper
        modules={[Pagination, Scrollbar, A11y,Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={{ clickable: true }}
        className="w-[100%]"
        autoplay={true}
      >
        <SwiperSlide className="flex items-center justify-center  py-12">
          <p className={style.para}>
                      Absolutely essential for our team! This app has helped us manage
                      an ongoing list of clients that we used to loose track off.
                      From the reports we receive from the app, we know who to give callbacks to
                      and keep pursuing until we convert them to clients. Highly recommended!
          </p>
        </SwiperSlide>
        <SwiperSlide className="flex items-center justify-center  py-12">
          <p className={style.para}>
                      Great tool for team coordination! I have set up all 4 of my therapists on here and
                      we are able to easily idetify no-show clients daily and reach out to them. A number
                      of people have noted that they love the reminders we send out and we can easily do so 
                      because of Suive.
                      
          </p>
        </SwiperSlide>
        <SwiperSlide className="flex items-center justify-center  py-12">
          <p className={style.para}>
            We used it for the first time during summer camp and I must say, this make attendance
            much more easy to take. 
          </p>
          </SwiperSlide>
         <SwiperSlide className="flex items-center justify-center">
          <p className={style.para}>
                      Game-changer for our team! We do not have everyone show up everyday so it is
                      great to be able to use Suive to monitor and send reports. The events also serve as a reminder for 
            leaders to contact the members prior to and after. 
          </p>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default Gallery;
