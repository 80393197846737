import React, { useState } from "react";

const SingleFeature = ({ Icon, heading, para, img, color }) => {
  const [isEnter, setisEnter] = useState(false);
  return (
    <div
      onMouseEnter={() => setisEnter(true)}
      onMouseLeave={() => setisEnter(false)}
      className={`cursor-pointer bg-neutral-50 px-12  w-[100%] md:w-[30%] mx-auto flex flex-col items-center mt-24 z-20 rounded-md ${
        !color && "featureBg"
      } ${isEnter && "featureBg"}`}
    >
      <div
        className={`bg-main max-w-fit p-6 rounded-full text-xl text-white scale-[1.5] md:scale-[2]`}
      >
        {Icon}
      </div>
      <h2
        className={` text-2xl md:text-3xl text-center font-medium mt-12 md:mt-16 text-gray ${
          !color && "text-white"
        } ${isEnter && "text-white"}`}
      >
        {heading}
      </h2>
      <h2
        className={` text-sm md:text-lg text-center my-4 md:my-4 text-stone-600 ${
          !color && "text-stone-100"
        } ${isEnter && "text-stone-100"}`}
      >
        {para}
      </h2>
      <img src={img} alt="" className="" />
    </div>
  );
};

export default SingleFeature;
