import React from "react";
import { AiFillApple } from "react-icons/ai";
import { BsGooglePlay } from "react-icons/bs";
const style = {
    heading:
        "text-4xl md:text-5xl lg:text-6xl text-white  my-7 md:my-3 w-[100%] md:w-[100%]  font-semibold text-center md:text-left",
    para: "text-white text-sm md:text-lg my-3 md:my-8  mx-auto md:mx-auto font-light text-center md:text-left",
    btn: " px-2 md:px-5 py-2 md:py-3 transition duration-[200ms] max-w-fit mx-auto md:mx-0 text-white text-xs md:text-sm",
    arrow: `text-xl text-white mx-2`,
    btnContainer:
        "transition duration-[250ms] font-normal cursor-pointer mx-auto md:mx-0  my-2 flex items-center justify-start rounded-lg",
    mainContent:
        "w-[90%] md:w-[50%] flex flex-col items-start justify-start  mx-auto font ",
};
const CTA = (props) => {
  return (
    <div className="bubble flex flex-col items-center justify-center">
      <h2 className="my-4 md:my-6 text-3xl md:text-5xl lg:text-6xl text-white text-center">
        Get started today
      </h2>
      <p className="my-4 md:my-6 text-lg md:text-xl lg:text-xl text-white text-center">
        Easy setup, No credit card required and 1 month free trial
      </p>
      <section className="flex flex-col md:flex-row items-center justify-center w-[100%]">
        {/*<a*/}
        {/*  href="https://apps.apple.com/us/app/suive/id6450641626"*/}
        {/*  className="flex items-center justify-center my-3 mx-4 md:mx-4 bg-white px-5 md:px-9 py-4 md:py-5 w-[60%] md:w-[20%] lg:w-[15%] hover:bg-opacity-[0.85] transition duration-[300ms]"*/}
        {/*>*/}
        {/*  <button className="text-center">Download App</button>*/}
              {/*</a>*/}

              <a
                  href={"https://apps.apple.com/us/app/suive/id6450641626"}
                  className="flex items-center justify-center bg-main rounded-md mx-3 px-2 hover:opacity-[0.9] "
              >
                  <AiFillApple className={style.arrow} />
                  <button className={style.btn}>APP STORE</button>
              </a>
              <a
                  href={"https://play.google.com/store/apps/details?id=com.bbrena.suivenew"}
                  className="flex items-center justify-center bg-main rounded-md mx-3 px-2 hover:opacity-[0.9] "
              >
                  <BsGooglePlay className={style.arrow} />
                  <button className={style.btn}>PLAY STORE</button>
              </a>

        <button
          onClick={() => props.onScroll("pricing")}
          className="my-3 mx-4 md:mx-4 bg-main text-white px-5 md:px-9 py-4 md:py-5 w-[60%] md:w-[20%] lg:w-[15%] hover:bg-opacity-[0.85] transition duration-[300ms]"
             >
          View App Pricing
        </button>
      </section>
    </div>
  );
};

export default CTA;
