import React from "react";
import Home from "../Home/Home";
import Features from "../Features/Features";
import Benefits from "../Benefits/Benefits";
import Pricing from "../Pricing/Pricing";
import Counter from "../Counter/Counter";
import Tools from "../Tools/Tools";
import Footer from "../Footer/Footer";
import CTA from "../CTA/CTA";
import Reviews from "../Reviews/Reviews";
import Screenshots from "../Screenshots/Screenshots";
import { scroller } from "react-scroll";
import Animator from "../UI/Animator";
import Faq from "../Faq/Faq";
const Main = () => {
  window.scrollTo(0, 0);
  const onScroll = (value) => {
    if (typeof window !== "undefined") {
      // browser code
      scroller.scrollTo(`${value}`, {
        duration: 900,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  return (
  	 <Animator>
    <div className="font bg-stone-50">
      <Home onScroll={onScroll} />
      <div className="features">
        <Features />
          </div>
          <Tools />
     {/* <Benefits />*/}
      <div className="pricing">
        <Pricing />
      </div>
          {/*<Counter />*/}
      <div className="cta">
          <CTA onScroll={onScroll} />
      </div>
      <div className="testimonials">
        <Reviews />
      </div>
    
      {/*<div className="screens">*/}
      {/*  <Screenshots />*/}
      {/*</div>*/}
   {/*   <Faq />*/}
     {/* <CTA onScroll={onScroll} />*/}
      <Footer onScroll={onScroll} />
    </div>
     </Animator>
  );
};

export default Main;
