import React from "react";
import PrivacyContent from "./PrivacyContent";
import Animator from "../UI/Animator";

import Footer from "../Footer/Footer";
const Privacy = () => {
  window.scrollTo(0, 0);
  const style = { wrapper: "" };
  const onScroll = () => {};
  return (
    <Animator>
      <div className={style.wrapper}>

        <PrivacyContent />
        <Footer onScroll={onScroll} />
      </div>
    </Animator>
  );
};

export default Privacy;
