import React from "react";
import SingleFeature from "./SingleFeature";
import { RxUpdate } from "react-icons/rx";
import { RiSecurePaymentLine } from "react-icons/ri";
import { SiTestcafe } from "react-icons/si";
import feature1 from "../../assets/features-1.png";
import feature2 from "../../assets/features-2.png";
import feature3 from "../../assets/features-3.png";
const Features = () => {
  return (
    <div className="z-10">
      {/* adding the feature content */}
      <div className="flex flex-col items-center justify-center my-24 md:my-16">
        <p className="text-main text-xl md:text-2xl mb-6 ">
          {/*What We’re Offering*/}
        </p>
        <h2 className="text-4xl md:text-6xl text-gray font-medium">
          Features
        </h2>
      </div>
      <div className="polka w-[20vw] h-[40vh] rounded-full absolute right-0 z-0 feature-animation  hidden md:flex"></div>
      {/* adding the features */}
      <div className="flex w-[100%] md:w-[95%] lg:w-[80%] mx-auto justify-between flex-col md:flex-row ">
        <SingleFeature
          Icon={<SiTestcafe />}
          heading="Get a Free App Trial"
          para="By taking advantage of the Free App Trial, you can explore all the functionalities the app has to offer. Discover how managing and keeping your groups and teams together can be simplified."
          img={feature1}
          color={true}
        />
        <SingleFeature
          Icon={<RxUpdate />}
          heading="Ease of Use"
          para="We've kept it simple, so you can too! Set up your account in minutes, invite other admins within your team and you're on your way."
          img={feature2}
          color={false}
        />
        <SingleFeature
          Icon={<RiSecurePaymentLine />}
          heading="Customize for your needs"
          para="Include as little or a much information as needed. Custom reports allow you to receive filtered data according to your team's needs"
          img={feature3}
          color={true}
        />
      </div>
    </div>
  );
};

export default Features;
