import React from "react";
import ToolsImg from "../../assets/5.png";
import { BsFillCheckCircleFill } from "react-icons/bs";
const Tools = (props) => {
  return (
    <div className="flex flex-col md:flex-row items-center mx-auto w-[100%] md:w-[85%] lg:w-[70%] my-16 md:my-24">
      {/* adding the content */}
      <div className="flex flex-col md:inline-block">
        <p className="text-main text-2xl md:text-3xl my-3 md:my-3 text-center md:text-left">
          New Features Released
        </p>
        <h2 className="text-gray text-4xl md:text-6xl font-normal my-3 md:my-3 text-center md:text-left">
          Powerful Tools For Group Management
        </h2>
        {/* setting the icons */}
        <p className="my-3 md:my-3 text-xl md:text-3xl p-2 text-center md:text-left text-main">
          Streamline member attendance, follow up and retention
        </p>
        {/* adding the tick icons */}
        <div className="flex flex-col items-start text-2xl md:text-2xl mx-4 md:mx-0 ">
          <section className="flex items-center justify-center my-2 md:my-3">
            <BsFillCheckCircleFill className="text-main text-2xl mr-4" />
            <p className="font-light text-xl md:text-2xl">
              Member records all in one place
            </p>
          </section>
        <section className="flex items-center justify-center my-2 md:my-3">
            <BsFillCheckCircleFill className="text-main text-2xl mr-4" />
                      <p className="font-light text-xl md:text-2xl">Manage Attendance</p>
          </section>
          <section className="flex items-center justify-center my-2 md:my-3">
            <BsFillCheckCircleFill className="text-main text-2xl mr-4" />
            <p className="font-light text-xl md:text-2xl">
              Recurring event setup
            </p>
                  </section>
           <section className="flex items-center justify-center my-2 md:my-3">
            <BsFillCheckCircleFill className="text-main text-2xl mr-4" />
            <p className="font-light text-xl md:text-2xl">
              Multiple Group Administrators
            </p>
          </section>
          <section className="flex items-center justify-center my-2 md:my-3">
            <BsFillCheckCircleFill className="text-main text-2xl mr-4" />
            <p className="font-light text-xl md:text-2xl">
              Custom Reporting
            </p>
          </section>
        </div>

        <button onClick={()=>props.onScroll('cta')} className="max-w-fit mx-auto md:mx-0 my-6 bg-main px-5 md:px-8 py-3 md:py-3 text-white tracking-wider text-xl rounded-md hover:bg-opacity-[0.95] transition duration-[300ms]">
          Contact support for custom features
        </button>
      </div>{" "}
      <img src={ToolsImg} alt="" className="scale-[0.8]" />
    </div>
  );
};

export default Tools;
