import React, { useState } from "react";
import SingleFeature from "./SinglePricing";
import { BiPaperPlane } from "react-icons/bi";
import { BsAirplaneFill } from "react-icons/bs";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import Switch from "../UI/Switch/Switch";
const Pricing = () => {
  const [PriceSwitch, setPriceSwitch] = useState(false);
  const handleSwitch = (value) => {
    setPriceSwitch(value);
  };
  return (
    <div className="z-10">
      {/* adding the feature content */}
      <div className="flex flex-col items-center justify-center my-24 md:my-8">
        <p className="text-main text-xl md:text-2xl mb-6 ">
          Our Pricing Tables
        </p>
        <h2 className="text-4xl md:text-6xl text-gray font-medium">
          Choose Pricing Plans
        </h2>
        <Switch switchValue={handleSwitch} />
      </div>
      {/* adding the Pricing */}
      <div className="flex w-[100%] md:w-[95%] lg:w-[70%] mx-auto justify-between flex-col md:flex-row z-10">
        <SingleFeature
          Icon={<BsAirplaneFill />}
          heading={`${!PriceSwitch ? "$9.99" : "$110"}`}
          smallHeading="Basic"
          para="By taking advantage of the Free App Trial, you can explore all the functionalities of our app has to offer. Dive into its intuitive interface, discover its Pricing, and witness how it can increase your productivity and streamline your workflow"
          color={true}
          details={['All app features','Groups of 50 or less','Max 5 daily reports']}
        />
        <SingleFeature
          Icon={<BsFillRocketTakeoffFill />}
          heading={`${!PriceSwitch ? "$19.99" : "$210"}`}
          smallHeading="Basic Plus"
          para="Our app boasts an intuitive and user-friendly interface, allowing users of all levels of technical expertise to navigate its Pricing with ease. Whether you're a seasoned professional or a tech novice, you'll find our app a breeze to use from day one. "
          color={false}
                  details={['50+ member teams', 'Unlimited daily Reports', 'All Basic features','24/7 Support', 'Push notification reminders']}

        />
        {/*<SingleFeature*/}
        {/*  Icon={<BiPaperPlane />}*/}
        {/*  heading="$40"*/}
        {/*  smallHeading="Ultimate Pack"*/}
        {/*  para="With our Secure Payment System, you can have peace of mind knowing that your sensitive payment information is encrypted and safeguarded against unauthorized access. We employ state-of-the-art encryption protocols and industry-standard security."*/}
        {/*  color={true}          details={['Extra Features','Life time free support','Upgrade Options','Full Access']}*/}

        {/*/>*/}
      </div>
    </div>
  );
};

export default Pricing;
