import React, { useState } from "react";
import bgImg from "../../assets/hero-bg.png";
import homeRight from "../../assets/7.png";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { Link } from "react-router-dom";
import { AiFillApple } from "react-icons/ai";
import { BsGooglePlay } from "react-icons/bs";
import Navbar from "../Navbar/Navbar";
const Home = (props) => {
  window.scrollTo(0, 0);
  const [Item, setItem] = useState("");
  const { width } = useWindowSize();
  const FeaturedBackground1 = styled.div`
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${bgImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: auto;
    height: 100vh;
    width: 100vw;z
  `;
  const style = {
    heading:
      "text-4xl md:text-5xl lg:text-6xl text-white  my-7 md:my-3 w-[100%] md:w-[100%]  font-semibold text-center md:text-left",
    para: "text-white text-sm md:text-lg my-3 md:my-8  mx-auto md:mx-auto font-light text-center md:text-left",
    btn: " px-2 md:px-5 py-2 md:py-3 transition duration-[200ms] max-w-fit mx-auto md:mx-0 text-white text-xs md:text-sm",
    arrow: `text-xl text-white mx-2`,
    btnContainer:
      "transition duration-[250ms] font-normal cursor-pointer mx-auto md:mx-0  my-2 flex items-center justify-start rounded-lg",
    mainContent:
      "w-[90%] md:w-[50%] flex flex-col items-start justify-start  mx-auto font ",
  };
  const handleScroll = (value) => {
    setItem(value);
  };
  props.onScroll(Item);
  return (
    <FeaturedBackground1 className="">
      <div className="h-full w-full">
        <Navbar onScroll={handleScroll} />
        <div className="flex flex-col md:flex-row items-center w-[100%] md:w-[90%] lg:w-[90%] mx-auto mt-4 md:mt-6 justify-center">
                  <div className={style.mainContent}>
                      <p className={"text-white text-2xl"}>
                        ~ verb ~
                      </p>  
            <h2 className={style.heading}>             
             follow | pursue | monitor
                      </h2>
                     
            <p className={style.para}>
                          Introducing the ultimate solution to store member information and harness
                          the features of suive to keep track of members.
                          For membership groups, youth groups, small or large groups, and the likes.
            </p>
            <div className={style.btnContainer}>
              <a
                href={"https://apps.apple.com/us/app/suive/id6450641626"}
                className="flex items-center justify-center bg-main rounded-md mx-3 px-2 hover:opacity-[0.9] "
              >
                <AiFillApple className={style.arrow} />
                <button className={style.btn}>APP STORE</button>
              </a>
              <a
                href={"https://play.google.com/store/apps/details?id=com.bbrena.suivenew"}
                className="flex items-center justify-center bg-main rounded-md mx-3 px-2 hover:opacity-[0.9] "
              >
                <BsGooglePlay className={style.arrow} />
                <button className={style.btn}>PLAY STORE</button>
              </a>
            </div>
          </div>
          {/* adding the image */}
          <img
            src={homeRight}
            alt=""
            className="home-animation w-[16rem] lg:w-[30rem] mt-12 md:mt-1 rounded-lg"
          />
        </div>
      </div>
    </FeaturedBackground1>
  );
};

export default Home;
