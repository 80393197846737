import React from "react";

const style = {
  wrapper:
    " flex flex-col md:flex-row items-center md:items-center md:mt-8 py-5 p-3 md:p-8",
  para: "text-md text-gray text-center mx-auto",
};
const Signature = () => {
  return (
    <div className={style.wrapper}>
      <p className={style.para}>
              | © Suive App |
              <a
                  href={"https://odilah.com"}
                  className="flex items-center justify-center  rounded-md mx-3 px-2 hover:opacity-[0.9] "
              >
              Powered by Odilah Tech |  All rights reserved 2023.
              </a>
              {/*© Suive App | The Suive App name and logo are registered*/}
              {/*trademarks ® of Suive App | All rights reserved 2023.*/}

      </p>
    </div>
  );
};

export default Signature;
