import React, { useState, useEffect } from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from "react-router-dom";
import NavDrawer from "./NavDrawer";
import "animate.css";
const Navbar = (props) => {
  const style = {
    navDrawer: "flex md:hidden items-center text-white ",
    wrapper:
      "text-gray-900 pt-1 md:py-6 z-10 w-[100%] md:w-[70%] bg-opacity-[0.9] mx-auto self-center font ",
    mainNav:
      "flex items-center justify-between py-1 md:py-1 hidden md:flex w-[100%] md:w-[90%] mx-auto",
    list: "font-normal border-move-animation list-none cursor-pointer text-white md:mx-4  text-md tracking-wide",
    navContainer: "flex items-center justify-center",
    cartButton:
      " font-normal rounded-md bg-white text-black tracking-wider flex items-center justify-center mx-5 bg-red px-5 py-2 text-md   hover:opacity-[0.90] transition duration-[250ms]",
    img: "cursor-pointer",
    input: "bg-transparent text-white cursor-pointer",
  };
  const [Enter, setEnter] = useState(false);
  const [Item, setItem] = useState("home");

  props.onScroll(Item);
  const handleScroll = (val) => {
    setItem(val);
  };

  return (
    <div className="border-b-[1px] border-[#7e22ce]">
      <div className={style.wrapper}>
        <nav className={style.mainNav}>
                  <div className={style.img}>
            <a href={"https://suiveapp.com"} >     
            <h2 className="text-3xl font-bold text-white tracking-wide"  onClick={() => setItem("features")}>
              Suive
            </h2> </a>
          </div>
          <ul className={style.navContainer}>
           {/* <li className={style.list}>Home</li>*/}
            <li className={style.list} onClick={() => setItem("features")}>
              Features
            </li>

            <li className={style.list} onClick={() => setItem("pricing")}>
              Pricing
            </li>

            {/*<li className={style.list} onClick={() => setItem("testimonials")}>*/}
            {/*  Testimonials*/}
            {/*</li>*/}
            {/*<li className={style.list} onClick={() => setItem("screens")}>*/}
            {/*  Screens*/}
            {/*</li>*/}

            <li className={style.list} onClick={() => setItem("cta")}>
              Download
            </li>

            {/*<li className={style.list} onClick={() => setItem("news")}>*/}
            {/*  News*/}
            {/*</li>*/}
          </ul>
          <Link to="/contact">
            <button className={style.cartButton}>
              Contact
              <AiOutlinePhone
                className={`text-2xl md:text-xl text-black   mx-2 ${
                  Enter && "animate__animated animate__tada"
                }`}
              />
            </button>
          </Link>
        </nav>
        <div className={style.navDrawer}>
          <NavDrawer onScroll={handleScroll} />
        </div>{" "}
      </div>
    </div>
  );
};

export default Navbar;
