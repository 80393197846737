import React, { useState } from "react";
import Switch from "react-switch";

const SwitchButton = (props) => {
  const [Checked, setChecked] = useState(false);
  const handleChangeSwitch = () => {
    setChecked((prev) => !prev);
  };
  props.switchValue(Checked)
  const style = {
    wrapper: "flex items-center justify-end p-3 my-4 md:my-7",
    eng: `${Checked ? "text-gray" : "text-gray"} mx-3 font-medium text-xl`,
    ptBr: `${Checked ? "text-gray" : "text-gray"} mx-3 font-medium text-xl`,
  };

  return (
    <div className={style.wrapper}>
      <p className={style.eng}>Monthly</p>
      <Switch
        onChange={handleChangeSwitch}
        checked={Checked}
        offColor="#1d4ed8"
        onColor="#1d4ed8"
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0"
      />{" "}
      <p className={style.eng}>Yearly</p>
    </div>
  );
};

export default SwitchButton;
