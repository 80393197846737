import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const style = {
  wrapper:
    "w-[90%] md:w-[60%] lg:w-[50%] mx-auto text-main my-12 md:my-24",
  smallHeading:
    "text-white font-semibold text-2xl md:text-3xl max-w-fit mx-auto py-2  my-8 md:my-10 font flex items-center justify-center flex-col text-center",
  formContainer: "flex flex-col font",
  input:
    "text-sm md:text-lg rounded-lg px-3  md:px-4 py-2 md:py-3 text-gray placeholder:text-gray ring-none outline-none bg-neutral-100 my-2 md:my-3 w-[100%] md:w-[70%] mx-auto",
  btn: "text-white bg-opacity-[0.8] hover:bg-opacity-[0.95] transition duration-[300ms]  px-7 md:px-5 py-2 md:py-3 tracking-wide text-center",
  btnContainer:
    "text-white p-1 mx-auto transition duration-[250ms] font-normal cursor-pointer hover:opacity-[0.9] my-2 flex items-center justify-center bg-main  px-4 rounded-lg w-[100%] md:w-[70%] mx-auto",
  label: "",
  checkbox: "w-[100%] md:w-[70%] mx-auto my-6 md:my-8 flex flex-col",
  option:
    "w-[100%] md:w-[70%] mx-auto my-6 md:my-8 bg-neutral-100 rounded-lg px-3  md:px-4 py-2 md:py-3",
  singleCheckBox: "flex items-center justify-end flex-row-reverse ",
};

const ContactForm = () => {
  const [isError, setError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSent, setisSent] = useState(false);
  const [Recaptcha, setRecaptcha] = useState(false);
  const [isClicked, setisClicked] = useState(false);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [BusinessName, setBusinessName] = useState("");
  const [WebsiteUrl, setWebsiteUrl] = useState("");
  const [TimeFrame, setTimeFrame] = useState("");
  const [selectedValue1, setSelectedValue1] = React.useState(Boolean);
  const [selectedValue2, setSelectedValue2] = React.useState(Boolean);
  const [selectedValue3, setSelectedValue3] = React.useState(Boolean);
  const [selectedValue4, setSelectedValue4] = React.useState(Boolean);
  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };
  const handleChange1 = (event) => {
    setSelectedValue1(event.target.checked);
  };
  const handleChange2 = (event) => {
    setSelectedValue2(event.target.checked);
  };
  const handleChange3 = (event) => {
    setSelectedValue3(event.target.checked);
  };
  const handleChange4 = (event) => {
    setSelectedValue4(event.target.checked);
  };

  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };
  const businessNameChangeHandler = (e) => {
    setBusinessName(e.target.value);
  };
  const websiteUrlChangeHandler = (e) => {
    setWebsiteUrl(e.target.value);
  };
  console.log(Recaptcha);
  const tokenWithWriteAccess =
    "skMYb2qhBcd5DgUBwQXHcpHWKn98TcBEGcApaVrGna7gUoQWDXC9VGRHJLy5G9CW7Fwhff0JmevJhBgxqh2Y22kWJR7R7E9fgDmJWDpIQGT558IOEMKAuKoVXg7v1rQ8trGDaMGG30c0nOhEwxW2Ad5ZnNL632XuIGX7i0aqOUWA1oV3XdaS";
  const submitHandler = (event) => {
    if (!Recaptcha) {
      setisClicked(true);
    }
    if (
      (Name != "" &&
        Email != "" &&
        // BusinessName != "" &&
        // WebsiteUrl != "" &&
        // TimeFrame != "",
      Message != "")
    ) {
      setError(false);
    } else {
      setError(true);
    }
    event.preventDefault();
    const sendData = async () => {
      setIsSubmit(true);
      const { data } = await axios.post(
        `https://1d8xcsdc.api.sanity.io/v2021-06-07/data/mutate/production?returnIds=true`,
        {
          mutations: [
            {
              create: {
                _type: "contact",
                createdAt: new Date().toISOString(),
                name: Name,
                email: Email,
                businessName: BusinessName,
                websiteUrl: WebsiteUrl,
                timeFrame: TimeFrame,
                newWebsite: selectedValue1,
                revampWebsite: selectedValue2,
                updateWebsite: selectedValue3,
                webHosting: selectedValue4,
                message: Message,
              },
            },
          ],
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${tokenWithWriteAccess}`,
          },
        }
      );
      // to represent the success message
      const flashTime = setTimeout(() => {
        setIsSubmit(false);
      }, 2000);
      return () => {
        clearTimeout(flashTime);
      };
    };
    const sendEmail = (e) => {
      let dataToBeSentToEmail = {
        createdAt: new Date().toISOString(),
        name: Name,
        email: Email,
        // businessName: BusinessName,
        // websiteUrl: WebsiteUrl,
        // timeFrame: TimeFrame,
        // newWebsite: selectedValue1,
        // revampWebsite: selectedValue2,
        // updateWebsite: selectedValue3,
        // webHosting: selectedValue4,
        message: Message,
      };
      emailjs
        .send(
          "service_75tlo9a",
          "template_h6l2e4k",
          dataToBeSentToEmail,
          "6BIY51JblL2XGIZJ8"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    };
    if (
      Name != "" &&
      Email != "" &&
      // BusinessName != "" &&
      // WebsiteUrl != "" &&
      // TimeFrame != "" &&
      Message != "" &&
      Recaptcha
    ) {
      // sendData();
      sendEmail();
      setName("");
      setEmail("");
      setBusinessName("");
      setWebsiteUrl("");
      setTimeFrame("");
      setMessage("");
      setSelectedValue1(false);
      setSelectedValue2(false);
      setSelectedValue3(false);
      setSelectedValue4(false);
      setisSent(true);
    }
  };
  //useEffect(() => {
  //  const interval = setInterval(() => {
  //    setisSent(false);
  //  }, 2000); // Change state every 1 second

  //  //return () => {
  //  //  clearInterval(interval);
  //  //};
  //}, [isSent]);
    const SITE_KEY = "6LdumUMoAAAAAN8DGT47EkDfEiEqMlhw2yhfG1nS";
    const SECRET_KEY = "6LdumUMoAAAAAFzT9aUzm179jS3g7DT5gt8hmJ8P";
  const captchaRef = useRef(null);
  const handleChanges = () => {
    setRecaptcha(true);
  };

  return (
    <div className={style.wrapper}>
      <h2 className={style.smallHeading}>
        Hi there, how can we help? 
              <p className="border-b-[4px] border-stone-300 w-[25vw] md:w-[10vw] mt-5 text-white"></p>
      </h2>
      <form className={style.formContainer}>
        <input
          type="name"
          className={style.input}
          placeholder="Your Name ..."
          value={Name}
          onChange={nameChangeHandler}
        />
        <input
          type="email"
          className={style.input}
          placeholder="Your Email ... "
          value={Email}
          onChange={emailChangeHandler}
        />
 
       
        <textarea
          rows="10"
          col="20"
          className={style.input}
          placeholder="Your Message here... "
          value={Message}
          onChange={messageChangeHandler}
        ></textarea>
        {isSubmit && (
          <p className=" my-4 [100%] md:w-[70%] mx-auto font bg-amber-500 text-white p-4 text-center w-[100%] rounded-lg">
            Message received Successfuly. Our Team will contact you soon
          </p>
        )}
        {isError && (
          <p className=" my-4 [100%] md:w-[70%] mx-auto font bg-red-600 text-white p-4 text-center w-[100%] rounded-lg">
            Please fill out correct details
          </p>
        )}
        {/* adding the recaptcha */}
        <div className="flex items-center justify-center ">
          <ReCAPTCHA
            sitekey={SITE_KEY}
            ref={captchaRef}
            onChange={handleChanges}
            className={`mx-auto my-5 md:my-6  p-2 ${
              isClicked && "border-2 border-red-300"
            }`}
          />{" "}
        </div>
        <div className={style.btnContainer} onClick={submitHandler}>
          <button className={style.btn}>Submit</button>
        </div>{" "}
        {isSent && (
          <p className={style.btnContainer}>
            Thank you. We have received your inquiry. Our team will contact you within 2 business days
          </p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
