import React from "react";
import ReviewsList from "./ReviewsList";
import styled from "styled-components";
import reviewImg from "../../assets/testimonial-bg.png";
import img1 from "../../assets/testimonial-1.png";
import img2 from "../../assets/testimonial-2.png";
import img3 from "../../assets/testimonial-3.png";
import img4 from "../../assets/testimonial-4.png";
import img5 from "../../assets/testimonial-5.png";
import img6 from "../../assets/testimonial-6.png";
import img7 from "../../assets/testimonial-7.png";
import img8 from "../../assets/testimonial-8.png";
const Reviews = () => {
  const ReviewBackground = styled.div`
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${reviewImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: auto;
    height: 85vh;
    width: 100vw;
  `;
  return (
    <ReviewBackground className="flex items-center justify-center">
      <section className="hidden md:flex  ">
        <img src={img1} alt="" className="home-animation mx-5 absolute mt-[-16rem] mx-12" />
        <img src={img2} alt="" className="home-animation mx-5 absolute mt-[-10rem] mx-16" />
        <img src={img3} alt="" className="home-animation mx-5 absolute mt-[-3rem] mx-24" />
        <img src={img4} alt="" className="home-animation mx-5 absolute mt-[6rem] mx-28" />
      </section>
      <ReviewsList />
      <section className="hidden md:flex  border-2">
      <img src={img1} alt="" className="home-animation mx-5 absolute mt-[-16rem] mx-[-16rem]" />
        <img src={img2} alt="" className="home-animation mx-5 absolute mt-[-10rem] mx-[-16rem]" />
        <img src={img3} alt="" className="home-animation mx-5 absolute mt-[-3rem] mx-[-16rem]" />
        <img src={img4} alt="" className="home-animation mx-5 absolute mt-[6rem] mx-[-16rem]" />
      </section>
    </ReviewBackground>
  );
};

export default Reviews;
