import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Signature from "./Signature";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiOutlineInstagram,
} from "react-icons/ai";
const style = {
  wrapper: "ml-0 text-gray tracking-wide",
  footerMenu: "flex flex-col ",
  footerContentContainer:
    "flex flex-col md:flex-row w-[100%] md:w-[70%] p-12 mx-auto",
  social: "flex md:w-[30%] my-7 md:my-3 mx-auto",
  menuHeading: "text-2xl md:text-2xl text-gray tracking-wider",
  para: "text-gray m-1 tracking-wider cursor-pointer border-move-animation-footer max-w-fit",
  infoContainer: "text-gray",
  infoHeading: "text-3xl md:text-4xl",
  infoPara: "mt-3 md:mt-4",
  left: "flex flex-col items-center md:items-start justify-between md:w-[50%] mx-3 md:mx-12",
  right:
    "flex flex-col md:flex-row md:w-[50%] md:items-start md:justify-center",
};
const Footer = (props) => {
  return (
    <div className={style.wrapper}>
      <section className={style.footerContentContainer}>
        <div className={style.footerMenu}>
     {/*     <h2 className={style.menuHeading}>FOOTER MENU</h2>*/}
                  <a href={"https://suiveapp.com"} >      
                      <p className={style.para}>Home</p>
                  </a>
          <p className={style.para} onClick={() => props.onScroll("features")}>
            Features
          </p>
          {/*<p className={style.para} onClick={() => props.onScroll("pricing")}>*/}
          {/*  Pricing*/}
          {/*</p>*/}
          <p
            className={style.para}
            onClick={() => props.onScroll("testimonials")}
          >
            Testimonials
          </p>
          {/*<p className={style.para} onClick={() => props.onScroll("screens")}>*/}
          {/*  Screens*/}
          {/*</p>*/}
          {/*<p className={style.para} onClick={() => props.onScroll("news")}>*/}
          {/*  News*/}
          {/*</p>*/}
        </div>
        <div className={style.social}>
          {/*<a href="/">*/}
          {/*  <AiFillFacebook className="text-3xl md:text-3xl mr-6 cursor-pointer text-gray" />*/}
          {/*</a>*/}
          {/*<a href="/">*/}
          {/*  <AiFillTwitterCircle className="text-3xl md:text-3xl mr-6 cursor-pointer  text-gray" />*/}
          {/*</a>*/}
          {/*<a href="/">*/}
          {/*  <AiOutlineInstagram className="text-3xl md:text-3xl mr-6 cursor-pointer  text-gray" />*/}
          {/*</a>*/}
        </div>
        <section className={style.infoContainer}>
                  <a
                      href={"https://suiveapp.com"}
                     
                  >          
        <h2 className={style.infoHeading}>Suive App
                    
                  </h2>
                     </a>
         {/* <p className={style.infoPara}>Ultime Team Management</p>*/}
        </section>
      </section>
      <Signature />
    </div>
  );
};

export default Footer;

{
  /* <li className={style.para} onClick={() => props.onScroll("about")}>
ABOUT US
</li> */
}
