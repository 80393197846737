import React from "react";
import ContactForm from "./ContactForm";
import Footer from "../Footer/Footer";
/*import Navbar from "../PrivacyNav/Navbar";*/
import Navbar from "../Navbar/Navbar";
import Animator from "../UI/Animator";
import { InlineWidget } from "react-calendly";
import styled from "styled-components";
import bgImg from "../../assets/hero-bg.png";

const FeaturedBackground1 = styled.div`
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${bgImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: auto;
    height: 100vh;
    width: 100vw;z
  `;
const Contact = () => {
  window.scrollTo(0, 0);

  const style = {
    wrapper: "font ",
    smallHeading:
      "font-semibold text-2xl md:text-3xl max-w-fit mx-auto py-2  my-3 md:my-5 font flex items-center justify-center flex-col text-center text-white",
  };
  const onScroll = () => {};
    return (
        <FeaturedBackground1 className="">
    <Animator>
      <div className={style.wrapper}>
        <Navbar onScroll={onScroll} />
        {/* <h2 className={style.smallHeading}>
          Schedule a meeting with our team
          <p className="border-b-[4px] border-stone-300 w-[25vw] md:w-[10vw] mt-5"></p>
        </h2>
        <div className="App">
          <InlineWidget url="https://calendly.com/odilah/15min" />
        </div> */}
        <ContactForm />
        <div className="">
          <Footer />
        </div>
      </div>
            </Animator>
        </FeaturedBackground1>
  );
};

export default Contact;
