import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import styled from "styled-components";
import iconImg from "../../assets/tools-line.png";
const SingleFeature = ({ Icon, heading, details, smallHeading, color }) => {
  const [isEnter, setisEnter] = useState(false);
  const FeaturedBackground1 = styled.div`
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${iconImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: auto;
    height: 10vh;
    width: 100vw;
  `;
  return (
    <div
      onMouseEnter={() => setisEnter(true)}
      onMouseLeave={() => setisEnter(false)}
      className={`overflow-hidden bg-white border-[1px] border-stone-200 cursor-pointer  w-[100%] md:w-[30%] lg:w-[30%] mx-auto flex flex-col items-center mt-14 z-20 rounded-md ${
        !color && "featureBg"
      } ${isEnter && "featureBg"}`}
    >
      <img
        src={iconImg}
        alt=""
        className=" opacity-[0.12] w-4 scale-[12] z-0"
      />
      <div
        className={`z-20 max-w-fit p-6 text-5xl md:text-7xl text-main rounded-full`}
      >
        {Icon}
      </div>
      <h2
        className={`z-10 px-12 text-5xl md:text-5xl lg:text-5xl text-center font-light mt-2 md:mt-3 text-gray ${
          !color && "text-white"
        } ${isEnter && "text-white"}`}
      >
        {heading}
      </h2>
      <h2
        className={`z-10  px-12 text-2xl md:text-3xl text-center font-light mt-4 md:mt-4 text-gray ${
          !color && "text-white"
        } ${isEnter && "text-white"}`}
      >
        {smallHeading}
      </h2>
      <p className="border-b-[1px] border-stone-200 w-[100%] my-3"></p>
      {details.map((singleDetail) => {
        console.log(singleDetail);
        return (
          <div className="flex items-center justify-center my-3">
            <AiOutlineCheck className="text-2xl md:text-3xl text-main mr-4" />
            <p className="text-slate-900 tracking-wider ">{singleDetail}</p>
          </div>
        );
      })}
      {/*<a href="https://apps.apple.com/us/app/suive/id6450641626">*/}
      {/*  <button className="my-5 hover:bg-white hover:text-main transition duration-[300ms] bg-main px-8 md:px-12 py-3 md:py-3 text-lg rounded-md mx-auto text-white tracking-wider ">*/}
      {/*    Get Started{" "}*/}
      {/*  </button>*/}
      {/*</a>*/}
    </div>
  );
};

export default SingleFeature;
